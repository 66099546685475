import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";


interface State {
    indexFolders: any
    indexTable: any
    selectedFolderName: any
    loading: any
    fileTableData: any
    modelActionstatus: any
    fileTableDataWithKeys: any
    fileTableDataWithKeysLoading: any
    categoryList: any
    addCategorymodelActionstatus: any
    addCategoryFlashbarStatus: any
    deleteCategoryModelActionstatus: any
}


const initialState: State = {
    indexFolders: "",
    indexTable: {
        data: {},
        status: null,
        error: null,
    },
    selectedFolderName: "test_index",
    loading: {
        status: false,
    },
    fileTableData: [],
    categoryList: [],
    modelActionstatus: false,
    fileTableDataWithKeys: {},
    fileTableDataWithKeysLoading: true,
    addCategorymodelActionstatus: false,
    deleteCategoryModelActionstatus: false,
    addCategoryFlashbarStatus: []
};

export const IndexPageSlice = createSlice({
    name: "IndexPage",
    initialState,
    reducers: {
        setIndexFolders: (state, action) => {
            state.indexFolders = action.payload;
        },
        setFileTableDataWithKeysLoading: (state, action) => {
            state.fileTableDataWithKeysLoading = action.payload;
        },
        setIndexTableData: (state: any, action: any) => {
            const existingFolder = state.fileTableData.find((item: any) => item.folder_name === action.payload.folder_name);

            if (existingFolder) {
                existingFolder.data = [...existingFolder.data, ...action.payload.data];
                existingFolder.status = action.payload.status
            } else {
                state.fileTableData.push(action.payload);
            }

            const newData: any = {};

            state.fileTableData.forEach((item: any) => {
                newData[item.folder_name] = {
                    index_name: item.index_name,
                    folder_name: item.folder_name,
                    data: item.data,
                    status: item.status
                }
            });
            state.fileTableDataWithKeys = newData

        },
        setSelectedFolderName: (state, action) => {
            state.selectedFolderName = action.payload;
        },
        setLoadingStatus: (state, action) => {
            state.loading.status = action.payload;
        },
        setUploadFileInTableData: (state: any, action: any) => {
            console.log(action)
            const currentDate = new Date();
            const formattedDate = format(currentDate, 'dd-MM-yyyy hh:mm a');
            const fileSizeInKB = action.payload.file.size / 1024;
            if (action.payload.file) {
                // Create new file object
                const newFile = {
                    "id": state.fileTableData.length + 1,
                    "FileKey": action.payload.file.name,
                    "File Size": `${fileSizeInKB.toFixed(2)} KB`,
                    "Last opened": formattedDate,
                    "Uploaded at": formattedDate,
                    "type": action.payload.file.name ? action.payload.file.name.split('.').pop().toUpperCase() : ''
                };

                // Add new file to the appropriate folder
                const folderName = action.payload.folder_name;
                if (state.fileTableDataWithKeys && state.fileTableDataWithKeys[folderName]) {
                    if (state.fileTableDataWithKeys[folderName].data) {
                        state.fileTableDataWithKeys[folderName].data.push(newFile);
                    } else {
                        state.fileTableDataWithKeys[folderName].data = [newFile];
                    }
                }
                // Create corresponding JSON file if it's a PDF
                // if (newFile.type === 'PDF') {
                //     const jsonFileName = `${action.payload.file.name.split('.')[0]}.json`;
                //     const newJsonFile = {
                //         "id": state.fileTableData.length + 1,
                //         "FileKey": jsonFileName,
                //         "File Size": `${fileSizeInKB.toFixed(2)} KB`,
                //         "Last opened": formattedDate,
                //         "Uploaded at": formattedDate,
                //         "type": 'JSON'
                //     };

                //     // Add JSON file to the same folder
                //     state.fileTableDataWithKeys[folderName].data.push(newJsonFile);
                // }
            }
            state.modelActionstatus = false;
        },
        setDeleteFileInTableData: (state: any, action: any) => {
            const { foldername, delete_filekey } = action.payload;
            // Check if the folder exists in the fileTableData
            if (state.fileTableDataWithKeys && state.fileTableDataWithKeys[foldername]) {
                // Check if the folder has a 'data' array
                if (state.fileTableDataWithKeys[foldername].data) {
                    // Filter out the files with matching file keys
                    state.fileTableDataWithKeys[foldername].data = state.fileTableDataWithKeys[foldername].data.filter(
                        (file: any) => !delete_filekey.includes(file.FileKey)
                    );
                }
            }
            state.modelActionstatus = false;
        },
        setDeleteFileCategory: (state, action) => {
            const folderName = action.payload.folder_name;
            if (state.fileTableDataWithKeys && state.fileTableDataWithKeys[folderName]) {
                delete state.fileTableDataWithKeys[folderName];
            }
            state.deleteCategoryModelActionstatus = false;
            state.modelActionstatus = false;
        },
        setModelActionstatus: (state, action) => {
            state.modelActionstatus = action.payload;
        },
        setAddCategoryModelActionstatus: (state, action) => {
            state.addCategorymodelActionstatus = action.payload;
        },
        setAddCategoryFlashbarStatus: (state, action) => {
            state.addCategoryFlashbarStatus = action.payload;
        },
        setCategoryList: (state, action) => {
            action.payload.data.forEach((item: any) => {
                const found = state.fileTableData.some((obj2: any) => obj2.folder_name === item.index_name);
                if (!found) {
                    state.fileTableData.push({
                        index_name: item.folder_name,
                        folder_name: item.index_name,
                        data: [],
                        status: 'loading'
                    })
                }
            });
            action.payload.data.forEach((item: any) => {
                if (!state.fileTableDataWithKeys[item.index_name]) {
                    state.fileTableDataWithKeys[item.index_name] = {
                        index_name: item.folder_name,
                        folder_name: item.index_name,
                        data: [],
                        status: 'loading'
                    }
                }
            });
            state.fileTableDataWithKeysLoading = false
            state.categoryList = action.payload;
        },
        setAddCategory: (state, action) => {
            const modifiedText = action.payload.folder_name.toLowerCase().replace(/ |-/g, '_');
            const categoryData = {
                folder_name: action.payload.folder_name,
                index_name: modifiedText
            }
            const fileTableData = {
                folder_name: modifiedText,
                index_name: action.payload.folder_name,
                data: [],
                status: 'completed'
            }
            state.categoryList.data.push(categoryData)
            state.fileTableData.push(fileTableData)
            state.fileTableDataWithKeys[modifiedText] = fileTableData
            state.addCategorymodelActionstatus = false;
        }
    },
});

export const {
    setIndexFolders,
    setIndexTableData,
    setSelectedFolderName,
    setLoadingStatus,
    setUploadFileInTableData,
    setDeleteFileInTableData,
    setModelActionstatus,
    setCategoryList,
    setFileTableDataWithKeysLoading,
    setAddCategory,
    setAddCategoryModelActionstatus,
    setAddCategoryFlashbarStatus,
    setDeleteFileCategory
} = IndexPageSlice.actions;

export const indexFoldersData = (state: any) => state.indexPage.indexFolders;
export const indexTableData = (state: any) => state.indexPage.fileTableData;
export const loadingStatus = (state: any) => state.indexPage.loading;
export const modelActionstatus = (state: any) => state.indexPage.modelActionstatus;
export const addCategoryModelActionstatus = (state: any) => state.indexPage.addCategorymodelActionstatus;
export const addCategoryFlashbarStatus = (state: any) => state.indexPage.addCategoryFlashbarStatus;
export const deleteCategoryModelActionstatus = (state: any) => state.indexPage.deleteCategoryModelActionstatus;
export const getcategoryList = (state: any) => state.indexPage.categoryList;
export const getFinalFileDataWithKeys = (state: any) => state.indexPage.fileTableDataWithKeys;
export const getFinalFileDataWithKeysLoading = (state: any) => state.indexPage.fileTableDataWithKeysLoading;

export default IndexPageSlice.reducer;
